/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-undef */

import axios from "axios";
import React from "react";
import { HttpHeaders } from "../shared/HttpHeaders";
import ChartTable from "./ChartTable";
import ReactECharts from 'echarts-for-react';
import { IChartData } from "../core/view-Model/chart.model";
import User from "../components/User";
import { H2, H5 } from "@tag/tag-components-react-v4";
import { NoEntryFilled } from "@tag/tag-icons";
import "../assets/css/common.scss";
import "../assets/css/chart.scss";

function Chart({
  chartName,
  chartAPIName,
  chartDetailAPIName,
  openDashboardScreen,
  SetopenDashboardScreen,
  fromDashBoard
}: any) {
  const Headers = HttpHeaders();
  const [showChart, setshowChart] = React.useState(false);
  const [chartData, setchartData] = React.useState<IChartData[]>([]);
  const [legendvalues, setlegendvalues] = React.useState<string[]>([]);
  const colorvalues = ["#AD230A", "#EFA611", "#2D864B"];
  const [openFilterUserScreen, setopenFilterUserScreen] = React.useState(false);

  // const onChartClick = (e: any) => {
  //   if (e.seriesName == "Driving Licence") {
  //     SetopenDashboardScreen(false);
  //     fromDashBoard("Driving Licence", "", e.data["levelHigh"], e.data["levelLow"]);
  //   } else if (e.seriesName == "Tax Due") {
  //     SetopenDashboardScreen(false);
  //     fromDashBoard("Tax Due", "", e.data["levelHigh"], e.data["levelLow"]);
  //   } else if (e.seriesName == "MOT") {
  //     SetopenDashboardScreen(false);
  //     fromDashBoard("Mot", "", e.data["levelHigh"], e.data["levelLow"]);
  //   } else if (e.seriesName == "Insurance") {
  //     SetopenDashboardScreen(false);
  //     fromDashBoard("Insurance", "", e.data["levelHigh"], e.data["levelLow"]);
  //   }
  // };

  const onChartClick = (e: any, echarts: any) => {
    if (e.seriesName == "Driving Licence") {
      SetopenDashboardScreen(false);
      fromDashBoard("Driving Licence", "", e.data["levelHigh"], e.data["levelLow"]);
    } else if (e.seriesName == "Tax Due") {
      SetopenDashboardScreen(false);
      fromDashBoard("Tax Due", "", e.data["levelHigh"], e.data["levelLow"]);
    } else if (e.seriesName == "MOT") {
      SetopenDashboardScreen(false);
      fromDashBoard("Mot", "", e.data["levelHigh"], e.data["levelLow"]);
    } else if (e.seriesName == "Insurance") {
      SetopenDashboardScreen(false);
      fromDashBoard("Insurance", "", e.data["levelHigh"], e.data["levelLow"]);
    }
  };

  React.useEffect(() => {
    bindChartData();
  }, []);

  const bindChartData = () => {
    setchartData([]);
    axios
      .get(`Admin/` + chartAPIName, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setchartData(response.data.data);
                const legendValuesArray: string[] = [];
                for (let i = 0; i < response.data.data.length; i++) {
                  legendValuesArray.push(response.data.data[i]["name"]);
                  if (response.data.data[i]["value"] > 0) {
                    setshowChart(true);
                  }
                }
                setlegendvalues(legendValuesArray);
              }
            }
          }
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind chart data!");
      });
  };

  const onEvents = {
    'click': onChartClick,
  }

  return (
    <div>
      {openDashboardScreen ?
        <div>
          {showChart ? (
            <>
              <ReactECharts
                style={{ height: '250px', width: '350px' }}
                onEvents={onEvents}
                option={{
                  shadow: true,
                  tooltip: {
                    trigger: "item",
                    formatter: "{b} <br/> {c} ({d}%)",
                    textStyle: {
                      fontSize: 10,
                    },
                  },
                  title: {
                    top: 10,
                    text: chartName,
                    left: "center",
                    textStyle: {
                      fontSize: 15,
                      color: "#666666",
                      fontWeight: 700,
                    },
                  },
                  legend: {
                    bottom: 10,
                    left: "center",
                    data: legendvalues,
                    textStyle: {
                      fontSize: 10,
                    },
                  },
                  color: colorvalues,
                  series: [
                    {
                      name: chartName,
                      type: "pie",
                      radius: ["35%", "65%"],
                      avoidLabelOverlap: false,
                      labelLine: {
                        length: 20,
                      },
                      emphasis: {
                        itemStyle: {
                          borderWidth: 0,
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                      },
                      label: {
                        formatter: "{c}",
                        position: "inside",
                      },
                      data: chartData,
                    },
                  ],
                }}
              />
              <ChartTable apiName={chartDetailAPIName} />
            </>
          ) : (
            <>
              <div className="chartGrid">
                <H5 className="chartName">{chartName}</H5>
                <div className="chartTable ">
                  <NoEntryFilled size="extraLarge" style={{ height: "100px", width: "100px",marginBottom: "15px" }} />
                  <H2>No items available</H2>
                </div>
              </div>
            </>
          )}
        </div> : null}
      {openFilterUserScreen ? (< User />) : null}
    </div>
  );
}
export default Chart;
