/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-dupe-else-if */

import { useIframePidgetSettings } from "@workspace/utils-react";
import axios from "axios";
import React, { useState, useRef } from "react";
import ManuallyAddVehicle from "../components/ManuallyAdd/ManuallyAddVehicle";
import { IUsers } from "../core/view-Model/users.model";
import { PidgetSettings } from "../PidgetSettings";
import { HttpHeaders } from "../shared/HttpHeaders";
import LoadingSpinner from "./../components/LoadingSpinner";
import ManuallyAddLicence from "./ManuallyAdd/ManuallyAddLicence";
import { IApprovalFilter } from "../core/view-Model/approval.model";
import ManualLicenceVehicleHistory from "./History/ManualLicenceVehicleHistory";
import { DataTable, SquareButton, Button, Pager, Modal, FormLayout, FormField, Input, DropDownNative } from "@tag/tag-components-react-v4"
import { HistoryFilled, ViewFilled, MicrosoftExcelFilled, SwitchesFilled } from '@tag/tag-icons';

function Approval({ openaApprovalScreen, SetopenaApprovalScreen }: any) {
  const approvedColor =
    '{"margin":"0 auto", "background-color": "#68B590","color": "white","padding": "3px","border-radius": "7px", "display": "block", "width": "80px","text-align":"center"}';
  const pendingColor =
    '{"margin":"0 auto", "background-color": "#eef0f2","color": "black","padding": "3px","border-radius": "7px", "display": "block", "width": "80px","text-align":"center"}';
  const rejectcolor =
    '{"margin":"0 auto", "background-color": "#e5173f","color": "white","padding": "3px","border-radius": "7px", "display": "block", "width": "80px","text-align":"center"}';
  const [openLicenceScreen, SetopenLicenceScreen] = useState(false);
  const [openLicenceHistoryScreen, SetopenLicenceHistoryScreen] = useState(false);
  const [fromLicenceHistoryScreen, SetFromLicenceHistoryScreen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openVehicleScreen, SetopenVehicleScreen] = useState(false);
  const [licencevehicleData, setLicencevehicleData] = useState([]);
  const pageSize = 10;
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [allVehicleApproved, setallVehicleApproved] = useState<IUsers[]>([]);
  const Headers = HttpHeaders();

  var registrationSearchNumber = "";
  var userId = "";
  var LicenceId = "";
  var VehicleId = "";
  var noHistoryPopup;
  const [taxDueDate, settaxDueDate] = React.useState("");
  const [motExpiryDate, setmotExpiryDate] = React.useState("");
  const [lastV5cIssuedDate, setlastV5cIssuedDate] = React.useState("");
  const [vehicleDetailsData, setvehicleDetailsData] = useState([]);
  const [licenceDetailsData, setlicenceDetailsData] = useState([]);
  const [licenceHistoryDetailsData, setlicenceHistoryDetailsData] = useState([]);
  const [makeforExport, setmakeforExport] = React.useState("");
  const [insStartDate, setinsStartDate] = React.useState("");
  const [insTerm, setinsTerm] = React.useState("");
  const [insExpiryDate, SetinsExpiryDate] = React.useState("");
  const [insPolicyNumber, SetinsPolicyNumber] = React.useState("");
  const [showManuallyAddVehicle, SetshowManuallyAddVehicle] = useState(false);
  const [showManuallyAddLicence, SetshowManuallyAddLicence] = useState(false);
  const [showManualLicenceHistory, SetshowManualLicenceHistory] = useState(false);
  const [statusValue, setStatusValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [datecometoUK, setdatecometoUK] = React.useState("");
  const [licenceno, setlicenceno] = React.useState("");
  const [issuedate, setissuedate] = React.useState("");
  const [issuedateDetail, setissuedateDetail] = React.useState("");
  const [expirydate, setexpirydate] = React.useState("");
  const [expirydateDetail, setexpirydateDetail] = React.useState("");
  const [fileName, setFilename] = React.useState("");
  const [historyPerformingDate, setHistoryPerformingDate] = React.useState("");
  const [historyRejectionReason, setHistoryRejectionReason] = React.useState("");
  const [historyPerformingUsername, setHistoryPerformingUsername] = React.useState("");
  const previousInputValue = useRef("");
  const lazyState = useRef({ LicenceVehicleHistoryPopUp: "" });

  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const userAvtar = url + "Content/Images/useravtar.png";
  const UpdateAllUsers = () => {
    setallVehicleApproved((x) =>
      [...x].map((i) =>
        i.isapprovedValue === "Verified"
          ? { ...i, highlightText: approvedColor }
          : i.isapprovedValue === "Reject"
            ? { ...i, highlightText: rejectcolor }
            : i.isapprovedValue === "Unverified"
              ? { ...i, highlightText: pendingColor }
              : i
      )
    );
    setIsLoading(false);
  };
  const [filterCount, setfilterCount] = React.useState('0');

  const [approvalFilterData, setApprovalFilterData] =
    React.useState<IApprovalFilter>({
      Name: "",
      VehicleRegistrationNumber: "",
      LicenceNo: "",
      Type: "",
      Status: "",
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    }); // DOC approval filter data

  const [openApprovalFilterScreen, SetOpenApprovalFilterScreen] = useState(false);
  const OnApprovalFilterOpened = (e: any) => { };

  const onRowClick = (e: any) => {
    if (e.row.original.licenceOrVehicleType === 'Licence') {
      registrationSearchNumber = e.row.original.licenceOrVehicleNumber;
      userId = e.row.original.userId;
      showLicenceDetails();
    }
    else {
      registrationSearchNumber = e.row.original.licenceOrVehicleNumber;
      userId = e.row.original.userId;
      showvehicleDetails();
    }
  };

  const onHistoryRowClick = (e: any) => {
    if (e.row.original.licenceOrVehicleType === 'Licence') {
      registrationSearchNumber = e.row.original.licenceOrVehicleNumber;
      userId = e.row.original.userId;
      LicenceId = e.row.original.licenceId;
      VehicleId = "";
      showlicnecehistoryDetails();
    }
    else {
      registrationSearchNumber = e.row.original.licenceOrVehicleNumber;
      userId = e.row.original.userId;
      VehicleId = e.row.original.vehicleId;
      LicenceId = "";
      showlicnecehistoryDetails();
    }
  };

  React.useEffect(() => {
    LoadAllApproveVehicleList(1);
  }, []);

  const handleApprovalUserPageChangeRequest = (e: any) => {
    LoadAllApproveVehicleList(e.page);
  };

  const showlicnecehistoryDetails = () => {
    setIsLoading(true);
    if (LicenceId !== "") {
      axios
        .get("approval/GetAllLicenceHistory?DOC_DVLA_MAH_LICENCE_PRIMARY=" + LicenceId + "&DOC_DVLA_MAH_TYPE= Licence", Headers)
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (response.data !== null && response.data !== undefined) {
              if (response.data.data !== null && response.data.data !== undefined) {
                if (response.data.data.length > 0) {
                  const data = response.data.data;
                  noHistoryPopup = false;
                  previousInputValue.current = 'false';
                  lazyState.current.LicenceVehicleHistoryPopUp = "false";
                  setlicenceHistoryDetailsData([]);
                  setlicenceHistoryDetailsData(data);
                  setHistoryPerformingDate(data.doC_DVLA_MAH_PERFORMING_DATE);
                  setHistoryRejectionReason(data.doC_DVLA_MAH_REJECTION_REASON);
                  setHistoryPerformingUsername(data.doC_DVLA_MAH_PERFORMING_USER_NAME);
                  SetshowManuallyAddLicence(true);
                  SetopenLicenceHistoryScreen(true);
                  SetFromLicenceHistoryScreen(true);
                  SetshowManualLicenceHistory(true);
                  setIsLoading(false);
                }
                else {
                  noHistoryPopup = true;
                  previousInputValue.current = 'true';
                  lazyState.current.LicenceVehicleHistoryPopUp = "true";
                  setlicenceHistoryDetailsData([]);
                  SetshowManuallyAddLicence(true);
                  SetFromLicenceHistoryScreen(true);
                  SetshowManualLicenceHistory(true);
                  setIsLoading(false);
                }
                setIsLoading(false);
              }
              setIsLoading(false);
            }
          }
        }
        )
    }
    else {
      axios
        .get("approval/GetAllLicenceHistory?DOC_DVLA_MAH_VEHICLE_PRIMARY=" + VehicleId + "&DOC_DVLA_MAH_TYPE= Vehicle", Headers)
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (response.data !== null && response.data !== undefined) {
              if (response.data.data !== null && response.data.data !== undefined) {
                if (response.data.data.length > 0) {
                  const data = response.data.data;
                  lazyState.current.LicenceVehicleHistoryPopUp = "false";
                  noHistoryPopup = false;
                  setlicenceHistoryDetailsData([]);
                  setlicenceHistoryDetailsData(data);
                  setHistoryPerformingDate(data.doC_DVLA_MAH_PERFORMING_DATE);
                  setHistoryRejectionReason(data.doC_DVLA_MAH_REJECTION_REASON);
                  setHistoryPerformingUsername(data.doC_DVLA_MAH_PERFORMING_USER_NAME);
                  SetopenLicenceHistoryScreen(true);
                  SetshowManualLicenceHistory(true);
                  SetFromLicenceHistoryScreen(false);
                  setIsLoading(false);
                }
                else {
                  setlicenceHistoryDetailsData([]);
                  noHistoryPopup = true;
                  lazyState.current.LicenceVehicleHistoryPopUp = "true";
                  SetFromLicenceHistoryScreen(false);
                  SetshowManualLicenceHistory(true);
                  setIsLoading(false);
                }
                setIsLoading(false);
              }
              setIsLoading(false);
            }
          }
        }
        )
    }
  };

  const showvehicleDetails = () => {
    setIsLoading(true);
    axios
      .get("vehicle/GetAllVehicle?REGISTERTION_NUMBER=" + registrationSearchNumber + "&DOC_USER_ID=" + userId, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                const data = response.data.data[0];
                setvehicleDetailsData(data);

                // setLicencevehicleData(response.data.data[0]);

                if (response.data.data[0].markedforexport === "false") {
                  setmakeforExport("No");
                } else if (response.data.data[0].markedforexport == null) {
                  setmakeforExport("");
                } else {
                  setmakeforExport("Yes");
                }
                var taxDueDate = new Date(data.taX_DUE_DATE);
                if (data.taX_DUE_DATE != null) {
                  var ddmmyyytaxDueDate =
                    taxDueDate.getDate() +
                    "/" +
                    (taxDueDate.getMonth() + 1) +
                    "/" +
                    taxDueDate.getFullYear();
                  settaxDueDate(ddmmyyytaxDueDate);
                } else {
                  settaxDueDate("");
                }

                var motExpiryDate = new Date(data.moT_EXPIRY_DATE);
                if (data.moT_EXPIRY_DATE != null) {
                  var ddmmyyymotExpiryDate =
                    motExpiryDate.getDate() +
                    "/" +
                    (motExpiryDate.getMonth() + 1) +
                    "/" +
                    motExpiryDate.getFullYear();
                  setmotExpiryDate(ddmmyyymotExpiryDate);
                } else {
                  setmotExpiryDate("  ");
                }

                var insStartDate = new Date(data.insurancE_START_DATE);
                if (data.insurancE_START_DATE != null) {
                  var ddmmyyyinsStartDate =
                    insStartDate.getDate() +
                    "/" +
                    (insStartDate.getMonth() + 1) +
                    "/" +
                    insStartDate.getFullYear();
                  setinsStartDate(ddmmyyyinsStartDate);
                } else {
                  setinsStartDate("  ");
                }

                if (data.insurancE_START_DATE != null && data.insurancE_EXPIRY_DATE != null) {
                  var insuranceStart = new Date(data.insurancE_START_DATE);
                  var insuranceExpiry = new Date(data.insurancE_EXPIRY_DATE);
                  var insuranceDateDifference = (insuranceExpiry.getFullYear() - insuranceStart.getFullYear()) * 12;
                  insuranceDateDifference -= insuranceStart.getMonth();
                  insuranceDateDifference += insuranceExpiry.getMonth();
                  insuranceDateDifference = insuranceDateDifference <= 0 ? 0 : insuranceDateDifference;

                  if (insuranceDateDifference === 1) {
                    setinsTerm("1 month");
                  } else if (insuranceDateDifference === 2) {
                    setinsTerm("2 month");
                  } else if (insuranceDateDifference === 3) {
                    setinsTerm("3 month");
                  } else if (insuranceDateDifference === 6) {
                    setinsTerm("6 month");
                  } else if (insuranceDateDifference === 12) {
                    setinsTerm("Annual");
                  }
                }

                var insExpiryDate = new Date(data.insurancE_EXPIRY_DATE);
                if (data.insurancE_EXPIRY_DATE !== null) {
                  var ddmmyyyinsExpiryDate = insExpiryDate.getDate() + "/" + (insExpiryDate.getMonth() + 1) + "/" + insExpiryDate.getFullYear();
                  SetinsExpiryDate(ddmmyyyinsExpiryDate);
                } else {
                  SetinsExpiryDate("  ");
                }

                var v5cissueddate = new Date(data.datE_OF_LAST_V5C_ISSUED);
                if (data.datE_OF_LAST_V5C_ISSUED !== null) {
                  var ddmmyyyisueDate =
                    v5cissueddate.getDate() +
                    "/" +
                    (v5cissueddate.getMonth() + 1) +
                    "/" +
                    v5cissueddate.getFullYear();
                  setlastV5cIssuedDate(ddmmyyyisueDate);
                } else {
                  setlastV5cIssuedDate("");
                }
              }
              SetshowManuallyAddVehicle(true);
              SetopenVehicleScreen(true);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      });
  };

  const showLicenceDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios
        .get("licence/GetAllLicence?LICENCENO=" + registrationSearchNumber + "&DOC_USER_ID=" + userId, Headers)
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (response.data !== null && response.data !== undefined) {
              if (response.data.data !== null && response.data.data !== undefined) {
                if (response.data.data != null) {

                  const data = response.data.data;
                  setlicenceDetailsData(data);
                  setlicenceno(response.data["data"].licenceno);
                  setLicencevehicleData(response.data["data"].licencedocument);

                  if (response.data["data"].licencedocument != null) {
                    setFilename(response.data["data"].licencedocument.doC_D_FILE_NAME);
                  }

                  if (response.data.data.ismanuallyaddlicence === true) {
                    var datecometoUKDt = new Date(response.data.data.datE_WHEN_YOUCOME_UK);
                    var ddmmyyydatecometoUKDate = datecometoUKDt.getDate() + "/" + (datecometoUKDt.getMonth() + 1) + "/" + datecometoUKDt.getFullYear();
                    setdatecometoUK(ddmmyyydatecometoUKDate);
                  }

                  var exdate = new Date(response.data["data"].expirydate);
                  if (response.data["data"].expirydate != null) {
                    var ddmmyyExpiryDate = exdate.getDate() + "/" + (exdate.getMonth() + 1) + "/" + exdate.getFullYear();
                    var ddmmyyExpiryDateDetail = exdate.getFullYear() + "/" + (exdate.getMonth() + 1) + "/" + exdate.getDate();
                    setexpirydate(ddmmyyExpiryDate);
                    setexpirydateDetail(ddmmyyExpiryDateDetail);
                  } else {
                    setexpirydate("  ");
                    setexpirydateDetail("  ");
                  }

                  var issueDate = new Date(response.data["data"].issuedate);
                  if (response.data["data"].issuedate != null) {
                    var ddmmyyyissueExpiryDate = issueDate.getDate() + "/" + (issueDate.getMonth() + 1) + "/" + issueDate.getFullYear();
                    var ddmmyyyissueExpiryDateDetail = issueDate.getFullYear() + "/" + (issueDate.getMonth() + 1) + "/" + issueDate.getDate();
                    setissuedate(ddmmyyyissueExpiryDate);
                    setissuedateDetail(ddmmyyyissueExpiryDateDetail);
                  } else {
                    setissuedate("  ");
                  }
                  // const data= data["data"];
                }
                SetshowManuallyAddLicence(true);
                SetopenLicenceScreen(true);
                setIsLoading(false);
              }
              setIsLoading(false);
            }
            setIsLoading(false);
          }
        }).catch((ex) => {
          console.log("Getting some API error when call ApprovedRejectManuallyAddVehicle API!");
        });
    } catch (error) {
      console.log("Getting some API error when call ApprovedRejectManuallyAddVehicle API!");
    }
  };

  const ExportAllApproveVehicleList = () => {
    setIsLoading(true);
    approvalFilterData.GetAll = true;
    approvalFilterData.CurrentPageNumber = 1;
    axios.post(`vehicle/GetAllApprovedVehicleListExport/`, approvalFilterData, { responseType: 'blob' })
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "ApproveLicenceVehicle";
            var fileType = response.data.type;
            var file = new Blob([response.data], { type: fileType });
            var approvalURL = URL.createObjectURL(file);
            const link = document.createElement("a");

            link.href = approvalURL;
            link.download = fileName;
            link.click();

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
      });
  };

  const LoadAllApproveVehicleList = (currentPage: number) => {
    setIsLoading(true);
    setallVehicleApproved([]);
    approvalFilterData.GetAll = false;
    approvalFilterData.CurrentPageNumber = currentPage;
    axios.post(`vehicle/GetAllApprovedVehicleList/`, approvalFilterData, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setallVehicleApproved(response.data.data);
                UpdateUserAvtar();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                UpdateAllUsers();
                setIsLoading(false);
                SetOpenApprovalFilterScreen(false);
              }
              else {
                setIsLoading(false);
                SetOpenApprovalFilterScreen(false);
              }
            } else {
              setIsLoading(false);
              SetOpenApprovalFilterScreen(false);
            }
          } else {
            setIsLoading(false);
            SetOpenApprovalFilterScreen(false);
          }
        } else {
          setIsLoading(false);
          SetOpenApprovalFilterScreen(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
      });
  };

  const UpdateUserAvtar = () => {
    setallVehicleApproved((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
  };

  const onParentRowClick = () => {
    LoadAllApproveVehicleList(1);
  };

  const OnApprovalFilterApplyClick = (e: any) => {
    let countOfFilter = 0;

    if (approvalFilterData.Name !== "" && approvalFilterData.Name !== null && approvalFilterData.Name !== undefined) {
      countOfFilter++;
    }

    if (approvalFilterData.VehicleRegistrationNumber !== "" && approvalFilterData.VehicleRegistrationNumber !== null && approvalFilterData.VehicleRegistrationNumber !== undefined) {
      countOfFilter++;
    }

    if (approvalFilterData.LicenceNo !== "" && approvalFilterData.LicenceNo !== null && approvalFilterData.LicenceNo !== undefined) {
      countOfFilter++;
    }

    if (approvalFilterData.Type !== "" && approvalFilterData.Type !== null && approvalFilterData.Type !== undefined) {
      countOfFilter++;
    }

    if (approvalFilterData.Status !== "" && approvalFilterData.Status !== null && approvalFilterData.Status !== undefined) {
      countOfFilter++;
    }

    setfilterCount(String(countOfFilter));
    LoadAllApproveVehicleList(1);
  };

  const OnApprovalFilterClick = (e: any) => {
    SetOpenApprovalFilterScreen(true);
  };

  function renderViewButton(row: any) {
    return (
      <SquareButton
        icon={<ViewFilled />}
        style={{ height: "24px", width: "24px", padding: "2px" }}
        onClick={() => onRowClick(row)}
      />
    );
  }

  function renderHistoryButton(row: any) {
    return (
      <SquareButton
        icon={<HistoryFilled />}
        style={{ height: "24px", width: "24px", padding: "2px" }}
        onClick={() => onHistoryRowClick(row)}
      />
    );
  }

  function renderCell(row: any) {
    return (
      <>
        <label style={row.row.original.isapprovedValue === "Verified" ? approvedColorDetail : row.row.original.isapprovedValue === "Unverified" ? pendingColorDetail : rejectcolorDetail}>{row.row.original.isapprovedValue}</label>
      </>
    )
  }

  const approvedColorDetail = { margin: "0 auto", backgroundColor: "#2D864B", color: "white", padding: "5px", borderRadius: "7px", width: "80px", textAlign: 'Center' };
  const pendingColorDetail = { margin: "0 auto", backgroundColor: "#CCE4E4", color: "black", padding: "5px", borderRadius: "7px", width: "80px", textAlign: 'Center' };
  const rejectcolorDetail = { margin: "0 auto", backgroundColor: "#AD230A", color: "white", padding: "5px", borderRadius: "7px", width: "80px" };

  const columns = React.useMemo(() => [
    {
      accessorKey: 'avatarURL320',
      header: () => <div style={{ textAlign: 'center', width: '50px' }}>User</div>,
      meta: {
        dataType: 'image',
        getCellProps: (cell: any) => {
          return { style: { width: '50px', textAlign: 'Center' } };
        },
      },
    },
    { accessorKey: 'fullName', header: 'Name' },
    { accessorKey: 'licenceNo', header: 'Licence No.' },
    { accessorKey: 'vehicleRegistrationNumber', header: 'Vehicle No.' },
    { accessorKey: 'licenceOrVehicleType', header: 'Type' },
    {
      accessorKey: 'isapprovedValue',
      header: () => <div style={{ textAlign: 'center' }}>Status</div>,
      cell: renderCell,
      meta: {
        getCellProps: (cell: any) => {
          return { style: { width: '140px', textAlign: 'Center' } };
        },
      },
    },
    {
      accessorKey: 'View',
      header: () => <div style={{ textAlign: 'center', width: "40px" }}>View</div>,
      cell: renderViewButton,
      meta: {
        getCellProps: (cell: any) => {
          return { style: { textAlign: 'Center', width: '50px' } };
        },
      },
    },
    {
      accessorKey: 'History',
      header: () => <div style={{ textAlign: 'center', width: "50px" }}>History</div>,
      cell: renderHistoryButton,
      meta: {
        getCellProps: (cell: any) => {
          return { style: { textAlign: 'Center', width: '50px' } };
        },
      },
    },
  ], [],);

  let handleTextChange = (evt: any) => {
    if (evt.target.name === "usr-name") {
      setApprovalFilterData({
        ...approvalFilterData,
        Name: evt.target.value,
      });
    }

    if (evt.target.name === "un-usr-drivingLicence") {
      setApprovalFilterData({
        ...approvalFilterData,
        LicenceNo: evt.target.value,
      });
    }

    if (evt.target.name === "un-usr-registrationNo") {
      setApprovalFilterData({
        ...approvalFilterData,
        VehicleRegistrationNumber: evt.target.value,
      });
    }


  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="fr mb_10">
            <div>
              <SquareButton
                icon={<MicrosoftExcelFilled />}
                accent="teal"
                style={{ marginRight: "5px" }}
                onClick={ExportAllApproveVehicleList} />
              <Button
                icon={<SwitchesFilled />}
                text="Filter"
                onClick={OnApprovalFilterClick}
                badge={filterCount}
                badgeAccent='teal'
              />
            </div>
          </div>
          <div>
            <DataTable columns={columns} data={allVehicleApproved} />
          </div>
          <Pager
            page={page}
            pageCount={pageCount}
            showFirstLast
            onPageRequest={handleApprovalUserPageChangeRequest}
            style={{ justifyContent: 'flex-end', padding: '10px', borderBottom: '1px solid #e2ecef' }}
          />
          <div>
            <Modal
              visible={openApprovalFilterScreen}
              accent="teal"
              heading="User Filter"
              width="small"
              primaryButtonProps={{ text: "Apply", accent: "teal" }}
              cancelButtonProps={{ text: "Clear" }}
              onClose={() => { SetOpenApprovalFilterScreen(false); }}
              onPrimaryButtonClick={OnApprovalFilterApplyClick}
              onCancelButtonClick={() => {
                approvalFilterData.Name = "";
                approvalFilterData.VehicleRegistrationNumber = "";
                approvalFilterData.LicenceNo = "";
                approvalFilterData.Type = "";
                approvalFilterData.Status = "";
                setfilterCount('0');
                LoadAllApproveVehicleList(1);
              }}
            >
              <FormLayout columns={1} rows={5}>
                <FormField
                  label="User Name"
                  editor={<Input name="usr-name" value={approvalFilterData.Name} onChange={handleTextChange} style={{ width: '100%' }} />}
                />

                <FormField
                  label="Driving Licence"
                  editor={<Input name="un-usr-drivingLicence" value={approvalFilterData.LicenceNo} onChange={handleTextChange} style={{ width: '100%' }} />}
                />

                <FormField
                  label="Registration No."
                  editor={<Input name="un-usr-registrationNo" value={approvalFilterData.VehicleRegistrationNumber} onChange={handleTextChange} style={{ width: '100%' }} />}
                />

                <FormField
                  label="Type"
                  editor={<DropDownNative
                    data={[
                      { label: "", value: "" },
                      { label: "Licence", value: "Licence" },
                      { label: "Vehicle", value: "Vehicle" },
                    ]}
                    value={approvalFilterData.Type}
                    style={{ "width": "100%" }}
                    onChange={(e) => {
                      approvalFilterData.Type = e.value;
                    }}
                  />}
                />

                <FormField
                  label="Status"
                  editor={<DropDownNative
                    data={[
                      { label: "", value: "" },
                      { label: "Verified", value: "Verified" },
                      { label: "Unverified", value: "Unverified" },
                      { label: "Reject", value: "Reject" },
                    ]}
                    value={approvalFilterData.Status}
                    style={{ "width": "100%" }}
                    onChange={(e) => {
                      approvalFilterData.Status = e.value;
                    }}
                  />}
                />

              </FormLayout>
            </Modal>
          </div>
        </div>
      )}

      {showManuallyAddLicence ? (
        <ManuallyAddLicence
          openLicenceScreen={openLicenceScreen}
          datecometoUK={datecometoUK}
          licenceNo={licenceno}
          issuedate={issuedate}
          expirydate={expirydate}
          fileName={fileName}
          licencevehicleData={licencevehicleData}
          licenceDetailsData={licenceDetailsData}
          SetshowManuallyAddLicence={SetshowManuallyAddLicence}
          SetopenLicenceScreen={SetopenLicenceScreen}
          onParentRowClick={onParentRowClick}
          issuedateDetail={issuedateDetail}
          expirydateDetail={expirydateDetail}
        />
      ) : null}

      {showManuallyAddVehicle ? (
        <ManuallyAddVehicle
          vehicleDetailsData={vehicleDetailsData}
          makeforExport={makeforExport}
          taxDueDate={taxDueDate}
          motExpiryDate={motExpiryDate}
          insStartDate={insStartDate}
          insTerm={insTerm}
          insExpiryDate={insExpiryDate}
          lastV5cIssuedDate={lastV5cIssuedDate}
          showManuallyAddVehicle={showManuallyAddVehicle}
          SetshowManuallyAddVehicle={SetshowManuallyAddVehicle}
          onParentRowClick={onParentRowClick}
          statusValue={statusValue}
        />
      ) : null}

      {showManualLicenceHistory ? (
        <ManualLicenceVehicleHistory
          openLicenceHistoryScreen={openLicenceHistoryScreen}
          showManualLicenceHistory={showManualLicenceHistory}
          licencevehicleData={licencevehicleData}
          licenceHistoryDetailsData={licenceHistoryDetailsData}
          SetshowManualLicenceHistory={SetshowManualLicenceHistory}
          SetopenLicenceHistoryScreen={SetopenLicenceHistoryScreen}
          fromLicenceHistoryScreen={fromLicenceHistoryScreen}
          previousInputValue={previousInputValue.current}
          LicenceVehicleHistoryPopUp={lazyState.current.LicenceVehicleHistoryPopUp}
          noHistoryPopup={noHistoryPopup}
          onParentRowClick={onParentRowClick}
        />
      ) : null}
    </div>
  );
}
export default Approval;